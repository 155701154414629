import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FullWidthBg, GridContainer } from '../../utilities/Containers'
import { HorizontalCard } from '../../elements'
import {
  below,
  h1,
  headingMarBottom,
  marginBottom,
  secondary,
} from '../../utilities'
import {
  CareerPlanAnim,
  CheckAnim, RocketAnim,
  SunAnim,
  WheelAnim,
  WorkshopAnim
} from "../../Animations/SvgAnimations"
import { useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const StepItem = styled(HorizontalCard)`
  text-align: left;
  img {
    height: 4rem;
    margin-bottom: ${headingMarBottom};
  }

  h2 {
    //margin-bottom: 3rem;
    font-weight: 600;
    padding-right: 2rem;
    ${below.small`
  padding-right: 0;
  `}
  }

  img,
  p {
    //height: 70px;
    //padding-right: 2rem;
    margin: ${marginBottom} 0;
  }

  .svg-inline--fa {
    //margin-bottom: ${marginBottom};
    font-size: 7rem;
    color: ${secondary};
    //opacity: 0.8; ahhhhhhhhhhhhhhhhhh - This was causing the fucknig icons to hang out over the header. WTF????
    //padding-top: 1rem;
    padding-right: 2rem;
    margin: 0 auto;
    ${below.small`
  padding-right: 0;
  `}
  }
  //For some reason its making me state a width when it gets smaller.
  ${below.small` 
  width: 20rem;
  `}
`

const variants = {
  visible: { opacity: 1, x: 0, y:0, transition: { type: 'spring', duration: 1  } },
  hidden: { opacity: 0, x: -200, y:300 },
}
const variants2= {
  visible: { opacity: 1, x: 0, y:0, transition: { type: 'spring', duration: 1 } },
  hidden: { opacity: 0, x: -200, y:300 },
}

const Process = () => {
  return (
    <FullWidthBg>
      <h2
        css={`
          font-weight: bold;
          font-size: ${h1};
          padding-top: 6rem;
        `}
      >
        The Process
      </h2>
      <hr
        css={`
          width: 70%;
          margin: ${headingMarBottom} auto 0 auto;
        `}
      />
      <GridContainer
        css={`
          padding-bottom: 3rem;
        `}
      >
        <StepOne />
        <StepTwo />
        <StepThree />
        <StepFour />
        <StepFive />
        <StepSix />
      </GridContainer>
    </FullWidthBg>
  )
}

export default Process

const StepOne = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <StepItem ref={ref} animate={controls} initial="hidden" variants={variants}>
      <h2>Clarify</h2>
      <SunAnim />
      <div>
        <p>
          {`At the start of the process we'll get to know each other, and I can start to get an idea for what your needs are in a practical sense, and also what your ideas are from a design perspective. We'll also go over some important decisions that need to be made from the start.`}
        </p>
      </div>
      {/*<Button modifiers="small" css={`margin-top: 0;`}>Learn More</Button>*/}
    </StepItem>
  )
}

const StepTwo = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <StepItem ref={ref} animate={controls} initial="hidden" variants={variants2}>
      <h2>Mock Up</h2>

      <WheelAnim />
      <p>
        {`I'll go ahead then and come up with a rough draft of the design, including the mobile version, and we can discuss and revise it until you give the go ahead!`}
      </p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </StepItem>
  )
}

const StepThree = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <StepItem ref={ref} animate={controls} initial="hidden" variants={variants}>
      <h2>Code</h2>

      <CareerPlanAnim />
      <p>
        {`Depending on if you chose Wordpress or React, I'll start development. Including adding in your e-commerce solution or booking system if relevant. You'll get regular check-ins from me, and I'll be asking you for input along the way. `}
      </p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </StepItem>
  )
}

const StepFour = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <StepItem ref={ref} animate={controls} initial="hidden" variants={variants2}>
      <h2>Training</h2>
      <WorkshopAnim />
      <p>{`All of my packages include zoom training sessions to help you understand more about all this. How to upload a blog, how to edit content (if using Wordpress), what on earth is SEO, and advice on text content you'll be creating. `}</p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </StepItem>
  )
}

const StepFive = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <StepItem ref={ref} animate={controls} initial="hidden" variants={variants}>
      <h2>SEO</h2>
      <CheckAnim />
      <p>
        {`Many of my packages include robust initial search engine optimisation - in other words, how we'll get your site on page one of Google. I'll be implementing this fully once the site is launched, although a long term SEO campaign is the most effective way to ensure we make it through Google's algorithms. `}
      </p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </StepItem>
  )
}

const StepSix = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <StepItem ref={ref} animate={controls} initial="hidden" variants={variants2}>
      <h2>Launch</h2>
      <RocketAnim />
      <p>{`Finally, we'll do one more minor revision and launch the site! We can include some extra Google Ads and Facebook targeted adds to help with driving initial traffic. `}</p>
      {/*<Button modifiers="small">Learn More</Button>*/}
    </StepItem>
  )
}
