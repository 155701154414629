import styled, { css } from 'styled-components'
import {
  attention,
  background,
  below,
  buttonColor,
  elevation,
  lineColor,
  marginBottom,
  navFontSize,
  primary,
  Sans,
  textColor,
} from '../utilities'
import { lighten } from 'polished'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { Link } from 'gatsby'

const BUTTON_MODIFIERS = {
  small: () => css`
    color: ${buttonColor};
    margin-top: 3rem;
    font-size: 1rem;
    padding: 3px 10px;
  `,
  cancel: () => css`
    background: ${attention};
    color: ${textColor};

    &:hover {
      background: ${lighten(0.1, attention)};
    }
  `,
  mobileNav: () => css``,
}

export const Button = styled(Link)`
  max-width: 300px;
  margin-top: 2rem;
  background: ${primary};
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-radius: 30px;
  float: left;
  color: ${buttonColor};
  border: solid 1px ${lineColor};
  //margin: 0 0.5rem ${marginBottom};

  transition: box-shadow 0.2s ease, background 0.1s ease;

  &:hover {
    ${elevation[3]};
    background: ${lighten(0.05, primary)};
  }

  text-decoration: none;
  font-size: ${navFontSize};

  ${applyStyleModifiers(BUTTON_MODIFIERS)}
`

export const NavButton = styled.button`
  :focus {
    outline: 0;
  }

  p {
    font-size: ${navFontSize};
    font-family: ${Sans};
    font-weight: 400;
    ${below.small`font-size:1.4rem !important;`}// this is for nav button on mobile...
  }

  position: relative;
  padding: 0.2rem 0.5rem;
  margin-left: 1rem;

  border: none;
  border-radius: 25px;
  background: ${background};
  //transition: box-shadow 0.2s ease, background 0.1s ease;
  //padding: 1rem;
  cursor: pointer;

  ${below.small`
  padding-right:0;
    p {background: none;
    font-size: 1.5rem;
    font-weight: normal;
    color: #222;}
  `}
  &:hover {
    ${elevation[1]};
    background: ${lighten(0.03, background)};
    @media only screen and (max-width: 768px) {
      box-shadow: none;
      background: none;
    }
  }
`

export const ExtLinks = styled.a`
  max-width: 300px;
  margin-top: 2rem;
  background: ${primary};
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-radius: 30px;
  float: left;
  color: ${buttonColor};
  font-size: 1.2rem;
  border: solid 1px ${lineColor};
  //margin: 0 0.5rem ${marginBottom};

  transition: box-shadow 0.2s ease, background 0.1s ease;

  &:hover {
    ${elevation[3]};
    background: ${lighten(0.05, primary)};
  }

  text-decoration: none;
  font-size: ${navFontSize};

  ${applyStyleModifiers(BUTTON_MODIFIERS)}
`

// export const CancelButton = styled(Button)`
//   background: ${attention};
//   color: ${textColor};
//   &:hover {
//     background: ${lighten(0.1, attention)};
//   }
// `
