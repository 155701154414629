import styled from "styled-components"
import { buttonColor, h1, h2, headerHeight, headingMarBottom } from "../utilities"

export const InfoPagesWrapper = styled.div`
  //display: inline-block;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 50%;
  margin: 0 auto;
  padding-top: calc(3rem + ${headerHeight});
  padding-bottom: 7rem;
  h1 {
    font-weight: bold;
    font-size: ${h1};
    margin-bottom: ${headingMarBottom};
    text-align: center;
  }
  a {
    margin: auto auto;
  }

  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`

// For pages that have multiple sections and images/svgs
export const SectionPageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  text-align: left;
  width: 50%;
  margin: 0 auto;
  padding-top: calc(3rem + ${headerHeight});
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: ${headingMarBottom};
    margin-top: 2rem;
  }
  h2 {
    font-weight: bold;
    font-size: ${h1};
    margin-bottom: ${headingMarBottom};
    margin-top: 4rem;
  }
  h3 {
    font-size: ${h2};
  }
  hr {
    margin-bottom: 4rem;
  }
  .svg-inline--fa {
    font-size: 5rem;
    height: 8rem;
    margin: 0 auto ${headingMarBottom} auto;
  }
  @media only screen and (max-width: 768px) {
    width: 85%;
  }
  img {
    height: 4rem;
    margin-bottom: ${headingMarBottom};
  }
  hr {
    width: 20%;
  }
`
