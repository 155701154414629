// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-career-development-js": () => import("./../../../src/pages/career-development.js" /* webpackChunkName: "component---src-pages-career-development-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hotel-mock-js": () => import("./../../../src/pages/hotel-mock.js" /* webpackChunkName: "component---src-pages-hotel-mock-js" */),
  "component---src-pages-how-i-work-mdx": () => import("./../../../src/pages/how-I-work.mdx" /* webpackChunkName: "component---src-pages-how-i-work-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musician-js": () => import("./../../../src/pages/musician.js" /* webpackChunkName: "component---src-pages-musician-js" */),
  "component---src-pages-orchard-house-js": () => import("./../../../src/pages/orchard-house.js" /* webpackChunkName: "component---src-pages-orchard-house-js" */),
  "component---src-pages-photography-portfolio-js": () => import("./../../../src/pages/photography-portfolio.js" /* webpackChunkName: "component---src-pages-photography-portfolio-js" */),
  "component---src-pages-shredding-site-js": () => import("./../../../src/pages/shredding-site.js" /* webpackChunkName: "component---src-pages-shredding-site-js" */),
  "component---src-pages-software-dev-blog-js": () => import("./../../../src/pages/software-dev-blog.js" /* webpackChunkName: "component---src-pages-software-dev-blog-js" */),
  "component---src-pages-tuner-js": () => import("./../../../src/pages/tuner.js" /* webpackChunkName: "component---src-pages-tuner-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

