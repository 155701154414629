import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { below, fixed, HamburgerSvg, headerBg, headerHeight, navFontSize, Sans, Serif, textColor } from "../utilities"
import { DropdownNav } from "./Nav"
import MobileNav from "./MobileNav"
import { NavButton } from "../elements"
import { useCycle } from "framer-motion"

const HeaderWrapper = styled.div`
  ${fixed({ x: "0", y: "0", xProp: "left", yProp: "right" })};
  background: ${headerBg};
  z-index: 1;
  opacity: 1.5;
  padding: 2rem 5rem;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${headerHeight};

  .main-nav {
    ${below.small`display: none;`};
    @media (max-width: 768px) {
      display: none;
    }
  }

  p {
    display: inline;
    padding-top: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-family: ${Serif};
    font-weight: 700;
    font-size: 3rem;
    ${below.med`font-size:2.5rem;`};
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  a {
    color: ${textColor};
    text-decoration: none;
    margin-bottom: 1rem;
    font-family: ${Serif};

  }

  nav a {
    font-family: ${Sans};
    margin-top: 1rem;
    text-decoration: none;
    font-size: ${navFontSize};
    //margin-left: 2rem;
    // Below is a feckin weird fix for a scoping problem with my MobileNav component
    ${below.small`
      font-size:1.4rem;`}
  }

  img {
    display: inline;
    //height: 3rem;
    margin-bottom: 0;
    margin-right: 1rem;
    padding-top: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem;
  }

  hr {
    margin-bottom: 0;
  }
`

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isOpen, toggleOpen] = useCycle(false, true)

  return (
    <HeaderWrapper>
      <div>
        {/*<img src={Tree} alt="Joan-Phelan-Logo" />*/}
        <p>
          <Link to="/">TC</Link>
        </p>
      </div>
      <HamburgerSvg isNavOpen={isOpen} onClick={() => toggleOpen(true)} />
      {/*<MenuToggle toggle={() => toggleOpen()} />*/}
      <MobileNav isNavOpen={isOpen} setIsNavOpen={toggleOpen} />
      <nav className="main-nav">
        <DropdownNav />
        {/*<NavButton>*/}
        {/*  <Link to="/contact">Blog</Link>*/}
        {/*</NavButton>*/}
        <NavButton>
          <Link to="/about">About</Link>
        </NavButton>
        <NavButton>
          <Link to="/contact">Contact</Link>
        </NavButton>
      </nav>
    </HeaderWrapper>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
