import React from 'react'
import styled from 'styled-components'
import { borderRadius, FlexWrapper, footerOpacity, footerTextColor, primary } from "../utilities"

export const FooterWrapper = styled.footer`
  height: auto;
  background: ${primary};
  color: ${footerTextColor};
  padding: 3rem 8rem;
  text-align: left;
  h3 {
  }
  hr {
    border-radius: ${borderRadius};
    border: solid 5px white;
    opacity: 0.2;
  }
  a {
    text-decoration: none;
    color: ${footerTextColor};
    display: block;
  }
  p {
    margin: 0;
  }
  @media (max-width: 768px) {
    padding: 3rem;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <hr />
      <h3>TC Web Design</h3>
      <FlexWrapper
        css={`
          justify-content: space-between;
          flex-direction: row;
          @media (max-width: 768px) {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 3rem;
            > div:nth-child(2) {
              grid-row: -1;
            }
          }
          //font-size: 80%;
        `}
      >
        <div>
          <p>Tom Caraher</p>
          <p>Inver Lodge</p>
          <p>Carrickmacross</p>
          <p>Co Monaghan</p>
          <p>Ireland</p>
        </div>
        <div>
          <a href="tel:+353-87-703-1412">+353-87-703-1412</a>
          <br/>
          <a href="mailto: info@tcwebdesign.ie">info@tcwebdesign.ie</a>
        </div>
        <div>
          <p>© {new Date().getFullYear()}, Tom Caraher</p>
          {/*<p>Designed by Tom Caraher</p>*/}
        </div>
      </FlexWrapper>
    </FooterWrapper>
  )
}

export default Footer
