import React, { useEffect, useRef, useState } from "react"
import { AnimatePresence, motion } from 'framer-motion'
import { lighten } from 'polished'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { background, below, borderRadius, navFontSize, Sans, Serif } from "../utilities"
import { NavButton } from '../elements'

// fix this to use your utility!!!
const MenuWrapper = styled(motion.div)`
  cursor: pointer;
  display: inline;
  overflow: hidden;
  button p {
    font-size: ${navFontSize};
    font-family: ${Sans};
    font-weight: 400;
    ${below.small`font-size:1.4rem;`} // this is for nav button on mobile...
  }
  :hover {
  }
  .links {
    border-radius: 20px;
    list-style-type: none;
    background-color: ${lighten(0.03, background)};
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    padding: 1rem 1rem;
    margin-top: 0.5rem;
    z-index: 5;
    ${below.small`
      align-items: flex-end;
      //Above aligns right...
      padding: 0; // these were screwing with the animation
      padding-right: 1rem;
      margin-top: 0;
      overflow: hidden;
      position: relative;
      background: none;
      text-align: right;
      
    `}
  }

  .links a {                                        
    font: ${Serif};
    font-size: ${navFontSize};
    margin-left: 0;
    //padding: 0.5rem 1rem;
    text-decoration: none;
    @media only screen and (max-width: 768px) {
      font-size: 1.1rem;
      text-align: right;
    }
    //border-radius: ${borderRadius};
    &:hover {
      border-bottom: solid 1px black;
    }
  }
`

const variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: { opacity: 0, height: 0 },
}

const arrowVariants = {
  // open: {
  //   rotate: 90,
  //   // // x: 0,
  // },
  // closed: { rotate: 0 },
}

export const DropdownNav = () => {
  const [isToggled, setIsToggled] = useState(false)
  const node = useRef();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsToggled(false);
  };

  useEffect(() => {
    if (isToggled) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isToggled]);

  return (
    <MenuWrapper ref={node}>
      <NavButton
        onClick={() => setIsToggled(prevState => !prevState)}
        aria-pressed="false"
      >
        <p>Examples</p>
        <motion.i
          animate={isToggled ? 'open' : 'closed'}
          initial="open"
          css={`
            display: inline-block;
            .fa-caret-down {
              //max-width: 2rem;
              //height:1.5rem;
            }
          `}
        >
          <motion.svg
            css={`
              margin-left: 8px;
              width: 1rem;
              height: 1rem;
              display: inline-block;
              font-size: 0.8rem;
              ${below.med`font-size: 1.1rem`}
            `}
            // variants={arrowVariants}
            className="svg-inline--fa fa-caret-down fa-w-10"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            role="button"
            aria-pressed="false"
          >
            <motion.path
              strokeWidth="3"
              stroke="black"
              strokeLinecap="round"
              transition={{ duration: 0.2 }}
              variants={{
                closed: {
                  d:
                    'M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z',
                },
                open: {
                  d:
                    'M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z',
                },
              }}
              initial="closed"
            />
          </motion.svg>
        </motion.i>
        {/*</div>*/}
      </NavButton>
      <AnimatePresence>
        {isToggled && (
          <motion.div
            className="links"
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <Link to="/career-development">Career Coach</Link>
            <Link  to="/orchard-house">Orchard House Pub, Kilkenny</Link>
            <Link to="/tuner">React Instrument Tuner</Link>
            <Link to="/musician">{`Musician's Site`}</Link>
            <Link to="/shredding-site">Security in Shredding</Link>
            <Link to="/photography-portfolio">Photography Portfolio</Link>
            <Link to="/software-dev-blog">Software Dev Blog</Link>
          </motion.div>
        )}
      </AnimatePresence>
    </MenuWrapper>
  )
}


