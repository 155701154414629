import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import styled from "styled-components"
import { background, below, secondary, secondBg } from "../utilities"
import {
  careerPath,
  checkPath,
  googlePath,
  mousePath,
  reactPath,
  rocketPath,
  sunPath,
  wheelPath,
  wordpressPath,
  WorkshopPath
} from "./SvgPaths"
import React, { useEffect } from "react"


export const SunAnim = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="sun"
      className="svg-inline--fa fa-sun fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height="100%"
      width="100%"
    >
      <motion.path
        initial="hidden"
        variants={{
          visible: {
            pathLength: 1,
            transition: {
              duration: 5
            }
          },
          hidden: { pathLength: 0 }
        }}
        stroke="#fff6e3"
        strokeWidth="15"
        fill={secondary}
        d={sunPath}
      />
    </motion.svg>
  )
}
export const WheelAnim = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="dharmachakra"
      className="svg-inline--fa fa-dharmachakra fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <motion.path
        initial="hidden"
        variants={{
          visible: {
            rotate: 360,
            transition: {
              // delay: 2,
              duration: 8
            }
          },
          hidden: { rotate: 0 }
        }}
        strokeWidth="20"
        fill={secondary}
        d={wheelPath}
      />
    </motion.svg>
  )
}
export const CheckAnim = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      className="svg-inline--fa fa-dharmachakra fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <motion.path
        initial="hidden"
        variants={{
          visible: {
            pathLength: 1,
            pathSpacing: 0,
            transition: {
              duration: 5
            }
          },
          hidden: { pathLength: 0, pathSpacing: 1 }
        }}
        transition={{ duration: 5 }}
        stroke="white"
        strokeWidth="20"
        fill={secondary}
        d={checkPath}
      />
    </motion.svg>
  )
}

const ServiceSVGVariants = {
  visible: {
    pathLength: 1,
    // pathSpacing: 0,
    // fill: secondary,
    transition: {
      duration: 3
    }
  },
  hidden: { pathLength: 0 }
}

export const CVAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      // data-prefix="far"
      data-icon="clipboard"
      className="svg-inline--fa fa-clipboard fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <motion.path
        // ref={ref}
        // animate={controls}
        initial="hidden"
        variants={ServiceSVGVariants}
        transition={{ duration: 5 }}
        stroke={secondary}
        strokeWidth="20"
        fill={background}
        d={mousePath}
      />
    </motion.svg>
  )
}

export const CareerPlanAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      class="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <motion.path
        initial="hidden"
        variants={ServiceSVGVariants}
        stroke={secondary}
        strokeWidth="20"
        fill={background}
        d={careerPath}
      />
    </motion.svg>
  )
}

export const InterviewPrepAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488 512"
    >
      <motion.path
        initial="hidden"
        variants={ServiceSVGVariants}
        stroke={secondary}
        strokeWidth="20"
        fill={background}
        d={googlePath}
      />
    </motion.svg>
  )
}

export const WorkshopAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <motion.path
        initial="hidden"
        variants={ServiceSVGVariants}
        stroke={secondary}
        strokeWidth="20"
        fill={background}
        d={WorkshopPath}
      />
    </motion.svg>
  )
}

export const SmileAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <motion.path
        initial="hidden"
        variants={ServiceSVGVariants}
        stroke={secondary}
        strokeWidth="10"
        // fillOpacity="0.4"
        fill={background}
        d={wordpressPath}
      />
    </motion.svg>
  )
}

export const OtherServAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <motion.path
        initial="hidden"
        variants={ServiceSVGVariants}
        stroke={secondary}
        strokeWidth="20"
        fill={background}
        d={reactPath}
      />
    </motion.svg>
  )
}

export const RocketAnim = (ref, controls) => {
  return (
    <motion.svg
      ref={ref}
      animate={controls}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <motion.path
        initial="hidden"
        variants={ServiceSVGVariants}
        stroke={secondary}
        strokeWidth="20"
        // fillOpacity="0.4"
        fill={secondBg}
        d={rocketPath}
      />
    </motion.svg>
  )
}

// function getRandomInt(min,max) {
//   min = Math.ceil(min)
//   max = Math.floor(max)
//   return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
// }

// function getRandom() {
//   const values = [-1000, 1000, -500, 500, 700, -700]
//   const random = values[Math.floor(Math.random() * values.length)]
//   console.log(random)
//   return random
// }

const Svg = styled(motion.svg)`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  max-height: 600px;
`

const Path = styled(motion.path)`
  fill: none;
  stroke: ${secondary};
  stroke-width: 13px;
  stroke-linecap: butt;
  stroke-miterlimit: 1.5;
`

const svg = {
  hidden: {},
  show: {
    transition: {
      // staggerChildren: 1,
      // delayChildren: 3,
      // staggerDirection: -1,
      // // when: "beforeChildren"
      // when: 'afterChildren',
    }
  }
}

const values = [-1000, 1000]

const ypath = {
  hidden: { y: values[Math.floor(Math.random() * values.length)], opacity: 0 },
  show: {
    rotate: 360,
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      // delayChildren: 1,
      duration: 1.5,
      type: "spring",
      // damping: 20,
      stiffness: 100
    }
  }
}

const xpath = {
  hidden: { x: values[Math.floor(Math.random() * values.length)], opacity: 0 },
  show: {
    rotate: 360,
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.5,

      duration: 2,
      type: "spring",
      // damping: 20,
      stiffness: 60
    }
  }
}

const xypath = {
  hidden: {
    x: values[Math.floor(Math.random() * values.length)],
    y: values[Math.floor(Math.random() * values.length)],
    opacity: 0
  },
  show: {
    rotate: 360,

    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.9,

      duration: 2.5,
      type: "spring",
      // damping: 20,
      stiffness: 50
    }
  }
}

const yxpath = {
  hidden: {
    x: values[Math.floor(Math.random() * values.length)],
    y: values[Math.floor(Math.random() * values.length)],
    opacity: 0
  },
  show: {
    rotate: 180,
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.2,

      duration: 3,
      // bounce: 0.25,
      type: "spring",
      // damping: 20,
      stiffness: 20
    }
  }
}

export const Ogham = () => {
  return (
    <div
      css={`
        height: 400px;
        width: 100%;
        margin-bottom: 3rem;
        //z-index: 3;
      `}
    >
      <Svg
        variants={svg}
        initial="hidden"
        animate="show"
        // width="100%"
        // height="100%"
        viewBox="0 0 190 1226"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        // xml:space="preserve"
        // xmlns:serif="http://www.serif.com/"
      >
        <Path
          variants={ypath}
          initial="hidden"
          animate="show"
          d="M83.561,4.251l0.523,917.179"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M84.084,32.945l77.618,-0.125"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M84.084,67.606l77.618,-0"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M84.084,102.122l77.618,0.391"
        />
        <Path
          variants={yxpath}
          initial="hidden"
          animate="show"
          d="M84.084,136.942l77.618,0.106"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M84.084,172.095l77.618,-0.159"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M6.004,207.956l155.698,48.471"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M6.004,242.233l155.698,48.723"
        />
        <Path
          variants={ypath}
          initial="hidden"
          animate="show"
          d="M50.403,327.633l68.33,-0.282"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M50.403,360.615l68.33,-0.063"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M50.403,431.32l68.33,0.645"
        />
        <Path
          variants={yxpath}
          initial="hidden"
          animate="show"
          d="M50.403,396.226l68.33,-0.148"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M50.403,465.635l68.33,0.811"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M84.568,529.085l79.075,0.327"
        />
        <Path
          variants={yxpath}
          initial="hidden"
          animate="show"
          d="M84.568,563.381l79.075,-0.214"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M84.568,596.662l79.075,0.13"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M84.568,631.55l79.075,-0.7"
        />
        <Path
          variants={ypath}
          initial="hidden"
          animate="show"
          d="M50.403,691.646l68.33,1.044"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M50.403,726.38l65.593,0.806"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M50.403,762.116l68.33,0.13"
        />
        <Path
          variants={yxpath}
          initial="hidden"
          animate="show"
          d="M50.403,796.002l68.33,-0.261"
        />
        <Path
          variants={xypath}
          initial="hidden"
          animate="show"
          d="M83.2,856.566l-78.949,-0.047"
        />
        <Path
          variants={xpath}
          initial="hidden"
          animate="show"
          d="M83.2,891.798l-78.949,0"
        />
      </Svg>
    </div>
  )
}
