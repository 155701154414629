export const lightBlack = '#00000014'
export const lightRed = '#e84545'
export const darkRed = '#903749'
export const darkPurple = '#53354a'
// export const darkBlue = '#2b2e4a'


// const black = '#000000'
// const darkBlue = '#14213D'
// const orangeYellow = '#FCA311'
// const lightGrey = '#E5E5E5'


export const darkGreen = `#283618`
export const lighterGreen = '#606C38'
export const cream = '#fdfcf3'
export const lightOrange = '#dda15e'
export const darkOrange = '#bc6c25'
export const lightGrey = '#e0e0e0'

// const lightBlack2 = '#020413'
// const darkSienna = '#120208'
// const rosewood = '#6A040F'
// const rossoCorsa = '#D00000'
// const vermilion = '#DC2F02'
// const persimmon = '#E85D04'
// const carrotOrange = '#E85D04'
// const orangeWeb = '#FAA307'
// const Yellow = '#FFBA08'


//Intentions

// weird black and red
// export const background = lightBlack2
// export const secondBg = darkSienna
// export const primary = rosewood
// export const secondary = carrotOrange
// export const textColor = "white"
// export const headerBg = lightBlack2
// export const lineColor = lightBlack2





// Cream and Green Theme
export const background = cream
export const secondBg = "white"
export const primary = darkGreen
export const secondary = lighterGreen
export const textColor = "black"
export const buttonColor = 'white'
export const headerBg = cream
export const lineColor = lightBlack
export const footerTextColor = lightGrey

// Dark theme with some blue/orange
// export const secondBg = darkBlue
// export const background = black
// export const primary = darkBlue
// export const secondary = orangeYellow
// export const textColor = "white"
// export const headerBg = black
// export const lineColor = lightBlack
