import React from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

// fix this to use your utility!!!
const ModalWrapper = styled(motion.div)`
  //position: fixed;
  text-align: right;
  height: auto;
  display: flex;
  flex-direction: column;
  //top: 30px;
  //Below is centering it. could also use flex box
  //left: 50%;
  transform: translate3d(-50%, 0, 0);
`

// eslint-disable-next-line react/prop-types
export const Modal = ({ isToggled, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <ModalWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
        >
          <motion.div initial={{ y: 50 }} animate={{ y: 0 }} exit={{ y: 30 }}>
            {children}
          </motion.div>
        </ModalWrapper>
      )}
    </AnimatePresence>
  )
}
