import styled from "styled-components"
import { attention, below, borderRadius, elevation } from "../utilities"
import { Button } from "./Buttons"
import { motion } from "framer-motion"

export const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background: ${attention};
  ${elevation[3]};
  border-radius: ${borderRadius};
  width: 80%;
  button {
    margin-top: 0;
  }
`

const CardButton = styled(Button)`
  width: 100%;
`
Card.Button = CardButton

export const HorizontalCard = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 4fr;
  justify-content: center;
  align-items: center ;
  padding: 2em;
  background: ${attention};
  ${elevation[3]};
  border-radius: ${borderRadius};
  button {
    margin-top: 0;
  }
  ${below.large`
  grid-template-columns: 1fr;
  h2 {
  text-align: center;
  }
  `}
`