import styled, { css } from "styled-components"
import { below, h1, secondBg, textColor } from "./index"
import { applyStyleModifiers } from "styled-components-modifiers"
// import {textColor} from "."

const CONTAINER_MODIFIERS = {
  contrasting: () => css`
    background: white;
    width: 80%;
  `,
  singleCol: () => css`
    grid-template-columns: 1fr;
    justify-items: center;
    height: auto;
  `,
  twoCol: () => css`
    grid-template-columns: 1fr 1fr;
    grid-gap: 10rem;
    @media (max-width: 950px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  `,
  threeCol: () => css`
    grid-template-columns: repeat(3, 1fr);
    height: auto;
    ${below.extraLarge`
      grid-template-columns: 1fr 1fr;
      width: 90%;
    `};
    ${below.extraSmall`grid-template-columns: 1fr;`}
  `,
  fourCol: () => css`
    grid-template-columns: repeat(4, 1fr);
    height: auto;
  `
}

export const GridContainer = styled.div`
  padding-top: 6rem;
  /* padding-bottom: 6rem; */
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  justify-items: center;
  /* text-align: center; */
  h2 {
    text-align: left;
    ${below.small`text-align:center;`};
    font-size: ${h1};
    font-weight: 700;
    color: ${textColor}
  }
  h3 {
    font-weight: normal;
    color: ${textColor}
  }
  ${below.large`
    width: 85%;
    // padding-top: 7rem;
    // padding-bottom: 7rem;
  `};
  ${below.small`
    grid-template-columns: 1fr;
    width: 90%;
    grid-gap: 1rem;
  `}
  ${applyStyleModifiers(
  CONTAINER_MODIFIERS
)} //.down-arrow {
  //  max-width: 4rem;
  //}
`

export const FullWidthBg = styled.div`
  background: ${secondBg};
  width: 100%;
  height: auto;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${below.small`align-items: center;`}
`

// export const GridWrapper = styled.div`
//   display: grid;
//   grid-gap: 3rem;
//   grid-template-columns: 1fr 1fr;
// `
