import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { DropdownNav } from "./Nav"
import { background, secondary, textColor } from "../utilities"

const MobileNavContainer = styled(motion.nav)`
  overflow: hidden;
  position: fixed;
  text-align: right;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${background};
  z-index: 10;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 1rem;
    margin: 3rem 1rem;
    overflow: hidden;
  }

  li {
    overflow: hidden;
    margin: 1.5rem 0;
  }

  li a {
    overflow: hidden;
    margin-top: 1rem;

    &:nth-child(1) {
      margin-top: 2rem;
    }
  }

  .close {
    position: fixed;
    top: 0;
    right: 1.5rem;
      //background-color: ${secondary};
      //color: ${textColor};
    border: none;
  }

  .dropdown {
    //margin-top: 4rem;
    //padding: 0;
    overflow: hidden;
    //margin: 1.5rem 0;
  }
`

const variants = {
  open: {
    opacity: 1,
    x: 0
  },
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      delay: 0.1
    }
  }
}
const liVariants = {
  open: {
    y: 0,
    opacity: 1
    // transition: {
    //   delay: 0.25,
    // },
  },
  closed: { y: -20, opacity: 0 }
}

const ulVariants = {
  open: {
    transition: {
      staggerChildren: 0.06,
      delayChildren: 0.06,
      staggerDirection: -1,
      when: "afterChildren"
    }
  },
  closed: {}
}

// const links = {
//   About: {to: "/about"}
// }

// eslint-disable-next-line react/prop-types
const MobileNav = ({ isNavOpen, setIsNavOpen }) => {
  return (
    <MobileNavContainer
      variants={variants}
      initial="closed"
      animate={isNavOpen ? "open" : "closed"}
      transition={{ damping: 200 }}
    >
      {/*<Button className="close" modifiers="small" onClick={() => setIsNavOpen(false)}>*/}
      {/*  Close*/}
      {/*</Button>*/}
      <motion.ul variants={ulVariants}>
        <motion.li variants={liVariants} className="dropdown">
          <DropdownNav />
        </motion.li>
        <motion.li variants={liVariants} className="links">
          <Link to="/about">About</Link>
        </motion.li>
        <motion.li variants={liVariants} className="links">
          <Link to="/contact">Contact</Link>
        </motion.li>
      </motion.ul>
    </MobileNavContainer>
  )
}

export default MobileNav
