import React from "react"
import styled from "styled-components"
import { below } from "./Breakpoints"
import { motion } from "framer-motion"

const MenuSvgWrapper = styled.div`
  display: none;
  ${below.small`display:flex; z-index:11;`};
`

const hvariants = {
  open: {
    rotate: 90
    // x: 0,
  },
  closed: { rotate: 0 }
}

export const HamburgerSvg = props => (
  <MenuSvgWrapper {...props} role="button" variants={hvariants} aria-pressed="false">
    <motion.svg
      initial="closed"
      variants={hvariants}
      animate={props.isNavOpen ? "open" : "closed"}
      width="23" height="23" viewBox="0 0 23 23"
      // viewBox="0 0 18 15"
      // width="20"
    >
      <motion.path
        // fill="transparent"
        strokeWidth="3"
        stroke="black"
        strokeLinecap="round"
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="black"
        strokeLinecap="round"
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="black"
        strokeLinecap="round"
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </motion.svg>
  </MenuSvgWrapper>
)

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)

export const MenuToggle = ({ toggle }) => (
  <button
    css={`
      z-index: 11;
      outline: none;
      border: none;
      //width: 30px;
      //height: 30px;
      border-radius: 50%;
      background: transparent;
    `}
    onClick={toggle}
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </button>
)
