//You've swapped these because you should've done an extra layer of naming as you dont want to go hunting around at the moment to fix
export const Serif = "Fira Sans"
export const Sans = "Montserrat"


// Font Sizes
export const baseFontSize = "112.5%/1.45em"
export const h1 = "2rem"
export const h2 = "1.62671rem"
export const h3 = "1.38316rem"
export const h4 = "1rem"
export const navFontSize = "1.1rem"
export const lineHeight = "1.1"

