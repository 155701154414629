import { GridContainer } from "../../utilities/Containers"
import { h1, secondary } from "../../utilities"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import styled from "styled-components"

const WorkWithContainer = styled.div`
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-weight: bold;
    font-size: ${h1};
    margin-bottom: 5rem;
  }

  svg {
    color: ${secondary};
    margin-right: 0.7rem;
  }

  button {
    margin-top: 5rem;
  }
`

const WorkGridWrapper = styled(GridContainer)`
  grid-template-columns: repeat(3, 1fr);
  height: auto;
  justify-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    column-gap: 1.5rem;
  }
`

export const WorkWith = () => (
  <>
    <WorkWithContainer>
      <h2>Who I Work With</h2>
      <WorkGridWrapper modifiers="threeCol">
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Small business owners in all sectors
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Artists
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Restaurants
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Musicians
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          GP Practices
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Life & Career Coaches
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Education Consultants
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Coffee Shops & Cafes
        </p>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} />
          Self-Employed
        </p>
      </WorkGridWrapper>
      {/*<Button>*/}
      {/*  <Link to="/who-I-work-with">Learn More</Link>*/}
      {/*</Button>*/}
    </WorkWithContainer>
  </>
)
