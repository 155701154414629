import { lineColor } from "./Colors"

export const borderRadius = '10px'
export const headerHeight = '3rem'
export const containerPadding = '2.5%'
export const marginBottom = '2rem'
export const headingMarBottom = '4rem'
export const sectionPadding ='6rem'
export const footerOpacity = '0.5'

export const hr = 'solid 1px' + lineColor
