/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import "fontsource-josefin-slab/600-normal.css"
// import "fontsource-josefin-slab/700-normal.css"
// import "fontsource-josefin-slab"
// import "fontsource-josefin-sans"

import styled from "styled-components"
import GlobalStyle from "../Global"
import Header from "./Header"
import Footer from "./Footer"
import { buttonColor, headerHeight } from "../utilities"
import SEO from "../seo"

const MainLayout = styled.main`
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  //padding-top: calc(3rem + ${headerHeight});
  a button {
    margin: 0 auto;
    color: ${buttonColor};
  }
`

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyle />
      <Header />
      <SEO title=""/>
      <MainLayout>{children}</MainLayout>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
